.password_page {
  min-height: inherit;
  background-color: $mainBgColor;
  color: $mainColor;
  font-family: $mainFontFamily;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    font-size: 1.25rem;
    letter-spacing: 1px;
    .title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: 5px;
    }
    .success_msg {
      text-align: center;
      div {
        width: 310px;
        color: rgba($mainBgColor, 1);
        background-color: $mainColor;
        margin: 3rem 0;
        padding: 1rem 0.5rem;
      }
      a {
        color: $secondColor;
        font-size: 1rem;
        text-decoration: underline;
      }
    }
    .expired_msg {
      text-align: center;
      div {
        width: 310px;
        margin: 3rem 0;
        padding: 1rem 0.5rem;
      }
      a {
        color: $secondColor;
        font-size: 1rem;
        text-decoration: underline;
      }
    }

    .hint {
      color: $secondColor;
      width: 320px;
      text-align: center;
      margin: 2rem 0;
    }
    .form_box {
      padding: 1rem;
      min-width: 300px;
      form > div {
        margin-bottom: 1rem;
      }
      .info_box {
        margin: 1rem 0 1.5rem 0;
        font-family: $mainFontFamily;
        p {
          color: $secondColor;
          letter-spacing: 1px;
        }
      }

      .submit_btn {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          background-color: $mainColor;
          background-position: 50% 45%;
          background-size: cover;
          color: black;
          font-weight: 500;
          outline: none;
          border: none;
          &:hover {
            background-color: $mainColorHover;
          }
        }
        & > div {
          margin-top: 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            margin-bottom: 1.5rem;
            color: $secondColor;
            font-size: 1rem;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
