.avatar_pop {
  & .ant-popover-inner {
    background-color: rgba($mainBgColor, 0.9);
    border: 5px solid $mainColor;
    border-radius: 5px;

    .avatar_pop_content {
      color: $secondColor;
      .avatar {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        & > div {
          box-sizing: border-box;
          width: 150px;
          height: 150px;
          border-radius: 50%;
          border: 2px dashed $secondColor;
          div {
            box-sizing: border-box;
            border-radius: inherit;
            overflow: hidden;
            width: 100%;
            height: 100%;
            border: 2px solid black;
            img {
              width: 100%;
            }
          }
        }
      }
      .user_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          padding-bottom: 10px;
        }
        div:nth-child(1) {
          color: $mainColor;
          font-size: 1.25rem;
          font-weight: 600;
          max-width: 190px;
        }
        div:nth-child(2) {
          color: rgba($secondColor, 0.9);
        }
      }
      .btns {
        padding: 20px 10px;
        display: flex;
        justify-content: space-between;
        a {
          padding: 2px 8px;
          background-image: $btnBgImage;
          background-position: 50% 45%;
          background-size: cover;
          color: black;
          letter-spacing: 1px;
          font-weight: 600;
          text-decoration: none;
          span svg {
            transform: translateY(-px);
          }
          &.logout_btn {
            background-image: $btnLogoutBg;
            background-position: 50% 35%;
            color: rgb(255, 255, 255);
          }
          &.disabled-link {
            pointer-events: none;
            opacity: 0.8;
          }
        }
      }
    }
  }
}

.logout_modal .ant-modal-content {
  background-color: rgba($mainBgColor, 0.8);
  border: 5px solid $mainColor;
  color: $secondColor;
  border-radius: 5px;
  padding: 20px;
  // height: 200px;
  .ant-modal-close {
    color: $secondColor;
    top: 8px;
    inset-inline-end: 8px;
  }
  .modal_text {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .modal_btns {
    display: flex;
    justify-content: space-around;
    button {
      box-sizing: border-box;
      border: 3px solid $mainColor;
      background-color: transparent;
      color: $secondColor;
      font-weight: 600;
      margin: 0 10px;
      &:last-child {
        padding: 1px 15px;
        background-color: $mainColor;
        color: black;
      }
      &:hover {
        border-color: $mainColorHover;
        background-color: $mainColorHover;
      }
    }
  }
}
