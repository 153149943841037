#home_page {
  .card_container {
    padding: 10px 20px;
    margin-bottom: 50px;
    min-height: 800px;
    @media (min-width: 768px) {
      padding: 10px 0;
    }
    .cards_masonry_grid {
      display: flex;
      margin-left: -30px; /* 調整間距 */
      width: auto;

      .cards_masonry_grid_column {
        padding-left: 30px; /* 調整間距 */
        background-clip: padding-box;
        .image_cards {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.end_card {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img_box {
    position: relative;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    border: 5px solid pink;
    padding: 10px;
    border-radius: 5px;

    img {
      width: 100%;
    }
  }
}
