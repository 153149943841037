.all_views {
  background-color: rgb(1, 0, 41);
  background-image: $homeBgImage;
  background-position: top;
  background-repeat: repeat-y;
  color: $secondColor;
  @media (min-width: 1200px) {
    background-size: cover;
  }
  .all_routes {
    box-sizing: border-box;
    padding-top: 60px;
    min-height: 85vh;
    // 頁面動畫
    .slide-enter {
      opacity: 0;
      transform: translateY(80vh);

      // animation: moveUp 10s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .slide-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 500ms ease-in-out;
    }

    .slide-exit {
      opacity: 1;
      transform: translateY(0);
      // animation: moveDown 10s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    .slide-exit-active {
      opacity: 0;
      transform: translateY(80vh);
      transition: transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 500ms ease-in-out;
    }
    @keyframes moveUp {
      0% {
        opacity: 0;
        transform: translateY(80vh);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
    @keyframes moveDown {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(80vh);
      }
    }
  }
}
