.image_cards {
  div {
    display: flex;
  }
  .box {
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    border: 2px solid rgba($mainColor, 0.7);
    overflow: hidden;
    &.not_public {
      border-color: rgb(220 53 69);
    }
    // header
    .card_header {
      padding: 0.5rem;
      background-color: black;

      .info {
        flex-grow: 1;
        padding-left: 10px;
        flex-direction: column;
        justify-content: space-around;
        overflow: hidden;

        .author {
          color: rgb(184, 184, 184);
          font-size: 12px;
        }
        div:first-child {
          justify-content: space-between;
          // margin-bottom: 5px;
          .title {
            flex-grow: 1;
            font-weight: 600;
            word-break: break-word;
          }
          .card_header_options {
            align-items: flex-start;
            margin-right: -5px;

            button {
              color: rgba($secondColor, 0.8);

              padding: 0;
            }
          }
        }
      }
    }
    // body
    .card_body {
      flex-direction: column;
      overflow: hidden;
      padding: 5px;
      .card_img {
        width: 100%;
        overflow: hidden;
        border-radius: 4px;
        img {
          width: 100%;
        }
      }
      .options {
        font-family: $mainFontFamily;
        font-size: 1.25rem;
        color: rgba($secondColor, 0.8);
        justify-content: space-between;
        padding: 2px 5px;
        div {
          align-items: center;
          &:hover {
            color: $secondColor;
          }
          & > span:first-child {
            margin-right: 5px;
            svg {
              vertical-align: -2px;
            }
          }
        }
      }
    }
  }
}
#card-menu {
  .MuiMenu-paper {
    padding: 0;
    background-color: rgba($secondBgColor, 0.95);
    color: $secondColor;
    .MuiList-root {
      padding: 0;

      hr {
        border-color: $secondColor;
        opacity: 0.6;
        margin: 4px 0;
      }
      li {
        padding: 0 10px;
        &:last-child {
          padding: 10px;
        }
        label {
          margin: 0;
        }
      }
    }
  }
}
