#footer {
  min-height: 15vh;
  padding: 1rem;
  background-image: $btnBgImage2;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Barlow Condensed", sans-serif;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 2rem;
    font-weight: 800;
  }
  p {
    font-size: 0.75rem;
    margin: 0;
  }
  .links {
    margin-bottom: 0.75rem;
    a {
      display: flex;
      color: black;
      padding: 2px;
      border-radius: 50%;
      transition: all 0.5s ease;
      animation: flashing 3s 0s ease-in-out infinite;
      &:hover {
        animation: none;
        color: rgb(255, 255, 255);
        background-color: black;
        transform: scale(1.2);
      }
      @keyframes flashing {
        25% {
          transform: rotate(20deg);
        }
        50% {
          opacity: 0.6;
        }
        75% {
          transform: rotate(-20deg);
        }
      }
    }
  }
}
