#login_page {
  min-height: inherit;

  background-color: $mainBgColor;
  color: $mainColor;
  font-family: $mainFontFamily;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    font-size: 1.25rem;
    letter-spacing: 1px;
    .title {
      font-size: 3rem;
      font-weight: 600;
      letter-spacing: 5px;
    }
    .form_box {
      padding: 2rem;
      form > div {
        margin-bottom: 1rem;
      }
      .submit_btn {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          background-color: $mainColor;
          background-position: 50% 45%;
          background-size: cover;
          color: black;
          font-weight: 500;
          outline: none;
          border: none;
          &:hover {
            background-color: $mainColorHover;
          }
        }
        & > div {
          margin-top: 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            margin-bottom: 1.5rem;
            color: $secondColor;
            font-size: 1rem;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
